import axios from "axios";

const vsCodeUrl = process.env.REACT_APP_VSCODE_URL || 'https://vscode.dev99lms.com/?folder=/home/ubuntu/EFS_DIR/users-android-pro-dev/';
const apkBuildServerUrl = process.env.REACT_APP_APK_BUILD_SERVER || 'https://androidbuild.dev99lms.com/build/buildProject';

const _http = axios.create({
  baseURL: process.env.REACT_APP_ANDROID_API || 'https://androididebe.dev99lms.com/api',
  headers:{
    "Content-Type": "application/json",
  }
});

const lms_v2_api = axios.create({
  baseURL: process.env.REACT_APP_LMS_V2_API || 'https://api-v2.dev99lms.com',
  headers:{
    "Content-Type": "application/json",
  }
})

export { _http, lms_v2_api, vsCodeUrl, apkBuildServerUrl };
