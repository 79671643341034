import { message } from "antd";
import React from "react";
message.config({
  maxCount: 1,
});
const Template = ({ imgSrc, imgTitle, setTemplate, template }) => {
  let className = "template";
  if (template === imgTitle) className = className + " template-selected";
  return (
    <div
      className={className}
      onClick={() => {
        setTemplate(imgTitle);
        message.info(`Selected ${imgTitle}`);
      }}
    >
      <img src={imgSrc} style={{boxShadow:"1px 1px 3px 1px gainsboro",}} alt="sample" width={"120px"} height={"160px"} />
    </div>
  );
};

export default Template;
