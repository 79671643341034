import { notification } from "antd";

const openNotification = (message,description,type) => {
    switch (true) {
      case type==='info':
        notification.info({
          message: message,
          description:description
        });   
        break;
      case type === 'success':
        notification.success({
          message: message,
          description:description
        }); 
        break;
      case type === 'error':
        console.log('error notification')
        notification.error({
          message: message,
          description:description
        }); 
        break;
      default:
        notification.open({
          message: message,
          description:description
        });   
        break;
    }
  };

  export default openNotification