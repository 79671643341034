import "./App.css";
import React, {  useEffect, useState } from "react";
import { Spin } from "antd";
import Header from "./components/Header";
import { _http, lms_v2_api } from "./services/android-ide-service";

const App = () => {

    const [authorized, setauthorized] = useState(false)
    const [userid, setUserid] = useState('userid');
    const [showSpinner, setShowSpinner] = useState(false);

    const _window = window?.parent ?? window.opener;

    const authToken = new URLSearchParams(window.location.search).get('token');

    useEffect(() => {
        if (authToken) {
            document.cookie = `auth_token=${authToken}; path=/; secure; SameSite=None; domain=.cybersquare.org;`;
            setShowSpinner(true);
            lms_v2_api.post('/lmsAuth/lmsToken/', {
                authToken
            }).then(res => {
                res = res.data;
                if (res.statusCode === 200) {

                    setUserid(res.userDetails.userLoginId);

                    _http.post('/folders/createuser', {
                        userid: res.userDetails.userLoginId
                    }).then(res => {
                        console.log(res.data);
                    });

                    _window.postMessage({ userid: res.userDetails.userLoginId, type: 'login', status: res.statusCode }, '*')

                    setauthorized(true);
                } else {
                    setauthorized(false);
                }

                setShowSpinner(false);
            });
        }
    }, [authToken, _window]);

    return (
        <div className="app-container">

            {
                !authToken ? (
                    <div style={{textAlign: "center", marginTop: 25}}>
                        <h1>No user token found</h1>
                    </div>
                ) : false
            }

            {
                (!authorized && !showSpinner && authToken) ? (
                    <div style={{textAlign: "center", marginTop: 25}}>
                        <h1>You are not authorized</h1>
                    </div>
                ) : false
            }

            {
                (authorized && !showSpinner) ? (
                    <div>
                        <Header userid={userid}/>
                    </div>
                ) : false
            }

            {
                showSpinner ? (
                  <div style={{
                    textAlign: 'center',
                    marginTop: '10rem'
                  }}>
                    <Spin/>
                  </div>
                ) : false
            }

        </div>
    )
}

export default App
