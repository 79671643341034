import { Button, Col, Modal, Row } from "antd";
import React, { useCallback, useState } from "react";
import styles from '../styles/common.module.css';
import Template from "./Template";
import basicactivity from "../images/basicactivity.png";
import bottomnavigation from "../images/bottomnavigation.png";
import emptyactivity from "../images/emptyactivity.png"
import fullscreenactivity from "../images/fullscreenactivity.png";
import openNotification from "../utils/notification";
import { _http } from "../services/android-ide-service";

const NewProject = (props) => {
    const [ActiveModal, setActiveModal] = useState(true)
    const [template, setTemplate] = useState("Basic Activity");
    const [focusedtemplate, setfocusedtemplate] = useState("")
    const [ActiveTab, setActiveTab] = useState(0)
    const [FooterBtnStatus, setFooterBtnStatus] = useState([false, true, true, false])
    const [projectname, setprojectname] = useState('android-hello-world')
    const [packagename,setpackagename] = useState('com.example.'+projectname)

    // useEffect(()=>{
    //     setActiveModal(false)
    // },[])
    const listOfTemp = [
        {
            name: "Phone and Tablet",
            disabled: false
        }
    ]

    const listOfActivity = [
        {
            imgSrc: bottomnavigation,
            imgTitle: "Bottom Navigation",
            disabled:false
        },
        {
            imgSrc: basicactivity,
            imgTitle: "Basic Activity",
            disabled:false
        },
        {
            imgSrc: emptyactivity,
            imgTitle: "Empty Activity",
            disabled:false
        },
        {
            imgSrc: fullscreenactivity,
            imgTitle: "Full screen Activity",
            disabled:false
        }
    ]
    const SelectActiveItem = "Phone and Tablet";

    /**
     * Handling Previous button action for Modal New Project
     */
    const handlePreviousBtn = useCallback(() => {
        if (ActiveTab !== 0) {
            setActiveTab(ActiveTab - 1)
            setFooterBtnStatus([false, true, true, false])
        }
    }, [ActiveTab])

    /**
     * Handling Next button action for Modal New Project
     */
    const handleNextBtn = useCallback(() => {
        if (ActiveTab === 0) {
            setFooterBtnStatus([true, false, true, true])
            setActiveTab(ActiveTab + 1)
        }

    }, [ActiveTab])

    /**
     * Handling Cancel button action for Modal New Project
     */
    const handleCancelBtn = useCallback(() => {
        props.showNewProject(false)
    }, [props])

    /**
     * Handling Finish button action for Modal New Project
     */
    const handleFinishBtn = useCallback(() => {
          const apiData = {
            userid: props.userid,
            foldername: projectname,
            template: template.replace(/ /g, "").toLowerCase(),
          };
          _http.post("/folders/create",apiData).then(res=>{
              if(res.status===200){
                props.showNewProject(false)
                openNotification('New Project','New project created successfully!','success')
                props.changeProject(projectname)
                setActiveModal(false)
              }
          }).catch(error=>{
            if (error.response) {
                console.log(error.response.data);
                openNotification("Error",error?.response.data?.error,'error')
                console.log(error.response.status);
              } else if (error.request) {
                openNotification("Error","some thing went wrong! please try again",'error')
              } else {
                console.log('Error', error.message);
              }
          })

    }, [ projectname, template,props])

    const handleprojectname = useCallback((event)=>{
       setprojectname(event.target.value)
       setpackagename('com.example.'+event.target.value)
    },[])

    return (
        <>
            <Modal
                title=""
                open={ActiveModal}
                centered
                style={{ top: 10 }}
                width={850}
                closable={false}
                footer={null}
                className={styles.androidStudioModal}
                maskStyle={{ 'background': 'none' }}
            >
                <div className={styles.ModalmainContent}>
                    <div className={styles.header}>
                        <span>New Project</span>
                    </div>
                    {ActiveTab === 0 &&
                        <div className={styles.ModalContent}>
                            <div className={styles.ModelContentTemp}>
                                <div className={styles.listoftemplatesHeading}>Templates</div>
                                <div className={styles.listoftemplates}>
                                    {listOfTemp.map((e, i) => {
                                        return <div key={`item${i}`} className={`${styles.listitem} ${SelectActiveItem === e.name ? styles.listitem_active : ''}`}><span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{e.name}</span></div>
                                    })}
                                </div>
                            </div>
                            <div className={styles.activitymaindiv}>
                                {SelectActiveItem === "Phone and Tablet" && <>
                                    <Row gutter={[32, 32]}>
                                        {listOfActivity.map((e, index) => {
                                            return <Col className="gutter-row" span={8} key={index}>
                                                <div style={e.disabled ? { background: 'rgba(0, 0, 0, 0.25)', cursor: 'not-allowed', pointerEvents: 'none', opacity: '0.5' } : {}} onClick={() => {
                                                    setfocusedtemplate(e.imgTitle)
                                                    setTemplate(e.imgTitle)
                                                }} className={`${template === e.imgTitle ? styles.activitymain : styles.activitymainfocusout} ${focusedtemplate === e.imgTitle ? styles.activitymainfocus : ''}`}>
                                                    <Template
                                                        imgSrc={e.imgSrc}
                                                        imgTitle={e.imgTitle}
                                                        setTemplate={setTemplate}
                                                        template={template}
                                                    />
                                                    <div className={`${template === e.imgTitle ? styles.activityfooter : styles.activityfooterfocusout} ${focusedtemplate === e.imgTitle ? styles.activityfooterfocus : ''}`}>
                                                        {e.imgTitle}
                                                    </div>
                                                </div>
                                            </Col>

                                        })}
                                    </Row>
                                </>}
                            </div>
                        </div>
                    }
                    {ActiveTab === 1 && <>
                        <div className={styles.ModalContent}>
                            <div className={styles.activity2ndpgContent}>
                                <div className={styles.activity2ndpgDiv}>
                                    <span >{template}</span>
                                </div>
                                <div className={styles.activity2ndpgDiv}>
                                    <p>Creats a new {template}</p>
                                </div>
                                <div className={styles.activity2ndpgDiv}>
                                    <div className={styles.activity2ndpgDivinner}>
                                        <span><u>N</u>ame</span>
                                    </div>
                                    <div className={styles.activity2ndpginput}>
                                        <input value={projectname} onChange={handleprojectname}></input>
                                    </div>
                                </div>
                                <div className={styles.activity2ndpgDiv}>
                                    <div className={styles.activity2ndpgDivinner}>
                                        <span><u>P</u>ackage name</span>
                                    </div>
                                    <div className={styles.activity2ndpginput}>
                                        <input value={packagename} disabled onChange={() => { }}></input>
                                    </div>
                                </div>
                                <div className={styles.activity2ndpgDiv}>
                                    <div className={styles.activity2ndpgDivinner}>
                                        <span><u>S</u>ave location</span>
                                    </div>
                                    <div className={styles.activity2ndpginput}>
                                        <input value={'/home/userid/'} disabled onChange={() => { }}></input>
                                    </div>
                                </div>
                                <div className={styles.activity2ndpgDiv}>
                                    <div className={styles.activity2ndpgDivinner}>
                                        <span><u>L</u>anguage</span>
                                    </div>
                                    <div className={styles.activity2ndpginput}>
                                        <input value={'java'} disabled onChange={() => { }}></input>
                                    </div>
                                </div>
                                <div className={styles.activity2ndpgDiv}>
                                    <div className={styles.activity2ndpgDivinner}>
                                        <span>Minimum SDK</span>
                                    </div>
                                    <div className={styles.activity2ndpginput}>
                                        <input value={'API 21:Android 5.0(Lollipop)'} disabled onChange={() => { }}></input>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>}
                    <div className={styles.ModalFooter}>
                        <Button className={styles.custmbtn} disabled={!FooterBtnStatus[0]} onClick={handlePreviousBtn}>Previous</Button>
                        <Button className={styles.custmbtn} disabled={!FooterBtnStatus[1]} onClick={handleNextBtn}><span style={{ textDecoration: 'underline' }}>N</span>ext</Button>
                        <Button className={styles.custmbtn} disabled={!FooterBtnStatus[2]} onClick={handleCancelBtn}><span style={{ textDecoration: 'underline' }}>C</span>ancel</Button>
                        <Button className={styles.custmbtn} disabled={!FooterBtnStatus[3]} onClick={handleFinishBtn}>Finish</Button>
                        {/* <Button className={styles.custmbtn} disabled={!FooterBtnStatus[3]} style={{ background: '#4d8aca', color: 'white' }} onClick={handleFinishBtn}>Finish</Button> */}
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default NewProject;