import React, { useCallback, useEffect, useState, useRef } from "react";
import { Select, Modal, Button, PageHeader, Menu, Progress, Spin, Alert } from "antd";
import NewProject from "./newProject";
import { _http, vsCodeUrl, apkBuildServerUrl } from "../services/android-ide-service";
import QRCode from 'react-qr-code';

const Header = ({ userid }) => {


  const [buildStack, setBuildStack] = useState([]);
  const [buildModalVisible, setBuildVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [buildDisabled, setDisabled] = useState(true);
  const [projectName, setProjectName] = useState(null);
  const [projectUser, setProjectUser] = useState(userid);
  const [ShowNewProject, setShowNewProject] = useState(false)
  const [changeProjectModal, setchangeProjectModal] = useState(false)
  const [selectedProject, setselectedProject] = useState('Select any project')
  const [items, setItems] = useState([]);
  const [buildProgress, setbuildProgress] = useState(0)
  const [DisablenewProject, setDisablenewProject] = useState(false)
  const [QRCodeModalVisible, setQRCodeModalVisible] = useState(false);
  const [iframeLoading, setiframeLoading] = useState(true);

  const buildStackRef = useRef(null);

  const hideSpinner = () => {
    setiframeLoading(false);
  };

  const showBuildModal = () => {
    setBuildVisible(true);
  };

  const handleBuildOk = () => {
    setBuildVisible(false);
  };

  const handleBuildCancel = () => {
    setBuildVisible(false);
  };

  /**
   * Handle disabling of Menu
   */
  const handleSaveDisable = useCallback((value) => {
    _http.post('/permission/remove', {
      "userid": value.userid,
      "projectname": value.projectName
    }, { validateStatus: false }).then(res => {
    }).catch(err => {
      console.log(err)
    })
    setDisablenewProject(true);
  }, [])

  /**
   * Handling project name change
   */
  const handlesetProjectName = useCallback((value) => {
    setProjectName(value)
    setselectedProject(value)
    _http.get(`/build/has-apk?userid=${projectUser}&projectname=${value}`)
      .then(res => setDisabled(!res.data.exists))
      .catch(_ => setDisabled(true))
  }, [projectUser])

  useEffect(() => {
    const fetchFolders = async () => {
      const apiData = {
        userid: projectUser
      };
      const res = await _http.post("/folders", apiData);
      res.data.pop();
      const folders = res.data.map(folderName => {
        return {value: folderName, label: folderName}
      });
      setItems(folders);
    };
    fetchFolders();
  }, [projectUser]);


  /**
   * Attaching event listener for change project from postmessage
   */

  useEffect(() => {
    if (!window) return;
    window.addEventListener('message', (e) => {
      if(e.data && e.data.action){
        if(e.data.action === "logout"){
          // handleLogout(e.data)
        } else if(e.data.action === "disableSave"){
          handleSaveDisable(e.data)
        } else if(e.data.action === "enableSave"){
          // setDisablenewProject(false)
        }            
      }
      else if(e.data && e.data.type === 'incoming') {
        setProjectUser(e.data.userid ? e.data.userid : projectUser);
        handlesetProjectName(e.data.projectName ? e.data.projectName : projectName);
      }
    })

  }, [projectUser, projectName, handleSaveDisable, handlesetProjectName])

  /**
   * Postmessage for LMS once the userid/Projectname changed
   */

  useEffect(() => {
    let _window = window?.parent ?? window.opener;
    _window.postMessage({ projectName: projectName, userid: projectUser, type: 'outgoing' }, '*')
  }, [projectUser, projectName])

  const fnViewQRCodeModal = () => {
    setQRCodeModalVisible(true);
  }

  const fnHideQRCodeModal = () => {
    setQRCodeModalVisible(false);
  }

  /**
   * Handle Build project
   */

  const handleBuild = useCallback(() => {

    setBuildStack([]);
    setLoading(true);

    const sse = new EventSource(`${apkBuildServerUrl}?userLoginId=${projectUser}&projectName=${projectName}`);
        function getRealtimeData(data) {

            try {
              setBuildVisible(true);
              let _data = JSON.parse(data);
           
              setbuildProgress(_data.progress);

              setBuildStack(preLog => [...preLog, _data.log]);

              buildStackRef?.current.scrollIntoView({behavior: 'smooth'});

              if (_data.log?.toLowerCase().includes('build failed')) {
                sse.close();
                setDisabled(true);
                setLoading(false);
              }

              if (_data.progress === 100) {
                setDisabled(false);
                setLoading(false);
                sse.close();
              }
            } catch (error) {
              console.log(error)
            }
        }
        sse.onmessage = e => getRealtimeData(e.data);
        sse.onerror = () => {
            setLoading(false);
            sse.close();
        }
        return () => {
            sse.close();
        };
  }, [projectUser, projectName])

  /**
   * Handling Project change action to display/hide modal
   */

  const handleChangeProject = useCallback(() => {
    setchangeProjectModal(!changeProjectModal)
  }, [changeProjectModal])

  /**
   * Handling Project select from choice
   */

  const handleSelectProject = useCallback((val) => {
    setselectedProject(val)
  }, [])

  /**
   * Handle Logout
   */

  //  const handleLogout = useCallback((value) => {
  //   _emu_http.get(`/deallocate?userid=${value.userid}`).then(res => {
  //     console.log(res)
  //     setEmulatorURL('')
  //   }).catch(err => {
  //     console.log(err)
  //   })
  // },[])

  return (
    <div>
      <div className="site-page-header-ghost-wrapper">
        <PageHeader
          style={{ maxHeight: "10vh" }}
          ghost={false}
          title="Android Editor"
          subTitle="Edit the code here and click submit"
          extra={[]}
        >
        </PageHeader>

        <Modal
          title="Build Stack"
          open={buildModalVisible}
          centered
          style={{ top: 10 }}
          closable={false}
          onOk={handleBuildOk}
          onCancel={handleBuildCancel}
        >
          <div>

            <div>
              <b><u>Build Progress</u></b>
              <Progress strokeColor={{
                  '0%': '#108ee9',
                  '100%': '#87d068',
                }}
                percent={buildProgress}
              />
            </div>

            <br/>

            <div>
              <b><u>Build Logs:</u></b>
              <br/>

              <div style={{
                maxHeight: '25vh',
                overflow: 'auto',
                background: '#3b3b3b',
                color: '#fff',
                borderRadius: '4px',
                padding: '4px'
              }}>

                { buildStack.map((e, index) => {
                  return (
                    <div key={index}>
                      <code>{e}</code>
                    </div>
                  )
                })}

                <div ref={buildStackRef} />

              </div>

              { loading ?
                <div style={{
                  textAlign: 'center',
                  marginTop: '5px'
                }}>
                  <Spin/>
                </div>
              : false }

            </div>

          </div>
        </Modal>

        <Modal
          title="View QR Code"
          open={QRCodeModalVisible}
          centered
          style={{ top: 10 }}
          onOk={fnHideQRCodeModal}
          onCancel={fnHideQRCodeModal}
        >
          <div style={{
              width: '100%'
            }}>

            <div style={{
              width: '100%',
              textAlign: 'center'
            }}>
              <QRCode
                title={projectName}
                value={`${_http.defaults.baseURL}/build/apk?userid=${projectUser}&projectname=${projectName}`}
              />
            </div>

            <div style={{
              width: '100%',
              textAlign: 'center'
            }}>
              <p style={{
                fontSize: '20px'
              }}>
                Scan this QR Code in your Android device to get the APK
              </p>
            </div>

          </div>
        </Modal>

        <Modal
          open={changeProjectModal}
          centered
          title="Change Project"
          style={{ top: 10 }}
          onOk={() => { handlesetProjectName(selectedProject); handleChangeProject() }}
          onCancel={handleChangeProject}>
          <Select 
            defaultValue="Select Project"
            value={selectedProject}
            style={{ width: '100%' }}
            onChange={handleSelectProject}
            options={items}>
          </Select>
        </Modal>
      </div>
      <div>
        <div>
          <Menu mode="horizontal"
            items = {
                [{
                    label: 'File',
                    key: 'submenu',
                    children: [{
                        label: 'New project',
                        key: 'submenu-item-1',
                        disabled: DisablenewProject,
                        onClick: () => {
                            setShowNewProject(true);
                        }
                    }, {
                        label: 'Open Project',
                        key: 'submenu-item-2',
                        disabled: DisablenewProject,
                        onClick: () => {
                            setchangeProjectModal(true);
                        }
                    }]
                }]
            }
          />
        </div>

        {ShowNewProject && <NewProject showNewProject={setShowNewProject} changeProject={handlesetProjectName} userid={projectUser}></NewProject>}

        {projectName &&
          <PageHeader
            style={{ maxHeight: "10vh" }}
            ghost={false}

            extra={[

              <Button key="3" onClick={showBuildModal} disabled={!buildStack.length}>
                Build Logs
              </Button>,

              <Button
                key="1"
                type="primary"
                loading={loading}
                disabled={!projectName}
                onClick={handleBuild}
              >
                Build Application
              </Button>,
              <Button
                key="0"
                type="primary"
                disabled={buildDisabled}
              >
                <a href={`${_http.defaults.baseURL}/build/apk?userid=${projectUser}&projectname=${projectName}`}>
                  Download APK
                </a>
              </Button>,
              <Button
                key="5"
                type="primary"
                disabled={buildDisabled}
                onClick={fnViewQRCodeModal}
              >
                View QR Code
              </Button>
            ]}
          >
          </PageHeader>
        }

        { (projectName && iframeLoading) ? 
          <div style={{
            textAlign: 'center',
            marginTop: '10rem'
          }}>
            <Spin size="large"/>
          </div>
        : false}

        {!projectName ?
          <div style={{
            padding: '5px',
            marginTop: '10px'
          }}>
            <Alert
              message="To create a new Android Project, click on File &#8594; New Project"
              type="info"
              showIcon
            />
          </div>
        : false }

        {projectName && <iframe
          src={`${vsCodeUrl}${projectUser}/${projectName}`}
          style={{ height: "90vh" }}
          allowFullScreen={true}
          width="100%"
          frameBorder="0"
          title="Editor"
          loading="lazy"
          onLoad={hideSpinner}
        ></iframe>}

        {projectName &&
          <PageHeader
            style={{ maxHeight: "10vh" }}
            ghost={false}
            extra={[

              <Button key="3" onClick={showBuildModal} disabled={!buildStack.length}>
                Build Logs
              </Button>,

              <Button
                key="1"
                type="primary"
                loading={loading}
                disabled={!projectName}
                onClick={handleBuild}
              >
                Build Application
              </Button>,
              <Button
                key="0"
                type="primary"
                disabled={buildDisabled}
              >
                <a href={`${_http.defaults.baseURL}/build/apk?userid=${projectUser}&projectname=${projectName}`}>
                  Download APK
                </a>
              </Button>,
              <Button
                key="5"
                type="primary"
                disabled={buildDisabled}
                onClick={fnViewQRCodeModal}
              >
                View QR Code
              </Button>
            ]}
          >
          </PageHeader>
        }
        
      </div>
    </div>
  );
};

export default Header;
